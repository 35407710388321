<template>
  <div class="app-container">
    <div class="block" v-if="url">
      <iframe :src="url" width="100%" :height="ifheight+'px'" frameborder="0" scrolling="auto"></iframe>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'iframePage',
  computed: {
    ...mapGetters(['iframeViews'])
  },
  data() {
    return {
      url: '',
      ifheight:0,
    }
  },
  mounted() {
    // if(this.iframeViews[this.$route.params.code].url.indexOf('screen') != -1){//是否是数据大屏页面
    //   window.open(this.iframeViews[this.$route.params.code].url)
    // }else{
    //   this.url = this.iframeViews[this.$route.params.code].url
    // }

    this.url = this.iframeViews[this.$route.params.code].url
    
    this.ifheight = document.documentElement.clientHeight - 35 - 45 -30
  }
}
</script>

